
  import { defineComponent } from "vue";
  import { Form, Field, ErrorMessage } from "vee-validate";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import * as Yup from "yup";
  import ApiService from "@/core/services/ApiService";
  
  export default defineComponent({
    name: "rs-file-upload",
    props: { taskList: "" as any },
    components: {
      Form,
      Field,
      ErrorMessage,
    },
    data() {
      return {
        entity: {
          entity_type_id: "",
          email: "",
          telephone: "",
          industry_sector: "",
          web_url: "",
          address: "",
          parent_entity_id: "",
          postcode: "",
          division: "",
          district: "",
          sub_district: "",
          entity_description: "",
          status: "",
          active_status: "1",
          status_remarks: "",
          fax: "",
          name: "",
          entity_short_name: "",
          registration_number: "",
          registration_date: "",
          registration_authority: "",
        },
        entityTypes: [],
        industry: [],
        entityInfos: [],
        divisions: [],
        districts: [],
        subDistricts: [],
        empDistricts: [],
        loading: false,
        files: [] as any,
        inputFiles: [] as any,
        fileNameByUser: {} as any,
        fileName: [] as any,
        fileEvent:[] as any,
      };
    },
    async created() {

    },
    methods: {
      async formSubmit() {
 
      },

      async getDivision() {
        await ApiService.get("geo/divisions")
          .then((response) => {
            this.divisions = response.data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      },
      async getDistrict() {
        await ApiService.get("geo/districts?division=" + this.entity.division)
          .then((response) => {
            this.districts = response.data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      },
      async getDistrictForEmp() {
        await ApiService.get("geo/districts")
          .then((response) => {
            this.empDistricts = response.data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      },
      async getSubDistrict() {
        await ApiService.get(
          "geo/upazilas?division=" +
          this.entity.division +
          "&district=" +
          this.entity.district
        )
          .then((response) => {
            this.subDistricts = response.data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      },
      uploadFile(){
      let button = '' as any;
        button = document.querySelector('.fileInput');
      if (button) {
        button.click();
      }
    }
    },
    
    setup() {

      return {
      };
    },
  });
  